import axios from 'axios';
import appsettings from '../appsettings';
const apiUrl = appsettings.serverBaseUrl + '/api/UserAircraft/';  


// -------------Get userAircraft list ----------------
export const getUserAircraftList = (accessToken: any) =>{
  return async (dispatch  :any) =>{
    try{
      //We are logged in the API so we don't need to pass again the userId
      const res = await axios.get<any>(apiUrl + "GetUserAircraftList/", {headers: {Authorization: 'Bearer ' + accessToken}});
      return dispatch(getUserAircraftListSuccess(res.data));
    }
    catch (error) {
      throw (error)
    }
  }
 }
 
 export const getUserAircraftListSuccess = (data :any) => {
  return {
    type: "USER_AIRCRAFT_LIST",
    payload: data
  }
 }

 //-----------Update userAircraft----------------------
 export const updateUserAircraft = (accessToken: any, userAircraft: any ) =>{
  return async (dispatch  :any) =>{
    try{
      //We are logged in the API so we don't need to pass again the userId
      const res = await axios.post<any>(apiUrl + "UpdateUserAircraft/",userAircraft, {headers: {Authorization: 'Bearer ' + accessToken}});
      return dispatch(updateUserAircraftSuccess(res.data));
    }
    catch (error) {
      throw (error)
    }
  }
 }
 
 export const updateUserAircraftSuccess = (data :any) => {
  return {
    type: "USER_AIRCRAFT_UPDATED",
    payload: data
  }
 }

  //-----------Save userAircraft----------------------
  export const saveUserAircraft = (accessToken: any, userAircraft: any ) =>{
    return async (dispatch  :any) =>{
      try{
        //We are logged in the API so we don't need to pass again the userId
        const res = await axios.post<any>(apiUrl + "SaveUserAircraft/",userAircraft, {headers: {Authorization: 'Bearer ' + accessToken}});
        return dispatch(SaveUserAircraftSuccess(res.data));
      }
      catch (error) {
        throw (error)
      }
    }
   }
   
   export const SaveUserAircraftSuccess = (data :any) => {
    return {
      type: "USER_AIRCRAFT_SAVED",
      payload: data
    }
   }

    //-----------Delete userAircraft----------------------
    export const deleteUserAircraft = (accessToken: any, aircraftId? :number) =>{
      return async (dispatch  :any) =>{
        try{
          const res = await axios.get<any>(apiUrl + "DeleteUserAircraft/" + aircraftId, {headers: {Authorization: 'Bearer ' + accessToken}});
          return dispatch(deleteUserAircraftSuccess(res.data));
        }
        catch (error) {
          throw (error)
        }
      }
    }
   
   export const deleteUserAircraftSuccess = (data :any) => {
    return {
      type: "USER_AIRCRAFT_DELETED",
      payload: data
    }
   }