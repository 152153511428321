import * as React from 'react';
import { Modal, Button } from "react-bootstrap";
import { connect } from 'react-redux';
import * as actionCreator from '../../actions/actions';
import { Dispatch } from 'redux';
import { Log } from "../../class/Log";
import Select from 'react-select';
import { MyLookup } from '../../class/Enums';
import { UserAircraft } from '../../class/UserAircraft';

interface State {
    userAircraftId: any;
    aircraftModel: any;
    aircraftRegistration: any;
}

interface Props {
    popupTitle: string;
    show: boolean;
    token: any;
    userAircraft: UserAircraft;
    aircraftModelList: any;
    userAircraftList: Array<UserAircraft>;
    hide(): void;
    saveUserAircraft(token: any, p: UserAircraft): void;
    updateUserAircraft(token: any, p: UserAircraft): void;
    getLookupList(): any;
}

class UserAircraftPopup extends React.Component<Props, State>{
    private socket: any;

    constructor(props: any) {
        super(props)
        this.state = {
            userAircraftId: 0,
            aircraftModel: "",
            aircraftRegistration: "",
        };
    }

    componentDidMount() {
       
        this.props.getLookupList();
    }

    componentDidUpdate(nextProps: any) {
        let aircraftLabel = this.props.userAircraft != undefined ? this.props.userAircraft.aircraftModel?.aircraftModelName : null;
        let aircraftValue = this.props.userAircraft != undefined ? this.props.userAircraft.aircraftModel?.aircraftModelId : null;

        //Edit selected logbook
        if (this.props !== nextProps) {
            this.setState({
                userAircraftId: this.props.userAircraft.userAircraftId,
                aircraftModel: { label: aircraftLabel, value: aircraftValue },
                aircraftRegistration: this.props.userAircraft.aircraftRegistration,
            })
        }
    }

    handleChange = (e: any) => {
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        this.setState({
            [e.target.id]: value
        } as any);
    }

    handleAircraftChange = (aircraftModel: any) => {
        this.setState({ aircraftModel: aircraftModel});
    };


    handleSaveLog = (e: any) => {
        e.preventDefault();
        var myAircraft: UserAircraft = ({
            userAircraftId: this.state.userAircraftId,
            aircraftModelId: this.state.aircraftModel.value,
            aircraftRegistration: this.state.aircraftRegistration,
            isAircraftFavorit: false
        });

        if (myAircraft.userAircraftId != undefined) {
            this.props.updateUserAircraft(this.props.token, myAircraft);
        }
        else {
            this.props.saveUserAircraft(this.props.token, myAircraft);
        }

       
        this.props.hide();
    }



    render() {

        // Extract the IDs of aircraft in the user's list
        // const userAircraftIds = this.props.userAircraftList.map(item => item.aircraftModel?.aircraftModelId);
        // const filteredAircraftList = this.props.aircraftModelList?.filter(
        //     (model: { value: number | undefined; }) => !userAircraftIds.includes(model.value)
        // );

        return (
            <div>
                <Modal className='modal-sm' show={this.props.show} onHide={() => this.props.hide()}>
                    <Modal.Header style={{ backgroundColor: '#2c2c2c' }} closeButton>
                        <Modal.Title>{this.props.popupTitle}
                        </Modal.Title>
                    </Modal.Header >
                    <Modal.Body style={{ backgroundColor: '#2c2c2c' }} >
                        <form id="newEntryForm" className="form-signin" onSubmit={this.handleSaveLog}>
                            <div className="mb-2 row">
                                <label className="col-sm-4 col-form-label">Aircraft</label>
                                <div className="col-sm-8">
                                    <Select id="aircraftModel" value={this.state.aircraftModel} onChange={this.handleAircraftChange} options={this.props.aircraftModelList} placeholder="Aircraft model" 
                                        styles={{
                                            control: (baseStyles) => ({
                                                ...baseStyles,
                                                padding: '0px',
                                                minHeight: '32px', // Ensure minimum height to avoid clipping content
                                            }),
                                            option: (provided) => ({
                                                ...provided,
                                                color: 'black', // or any other color that provides good contrast
                                            }),
                                            // Optionally, you can add more styling for other components like the menu, option, etc.
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="mb-2 row">
                                <label className="col-sm-4 col-form-label">Registration</label>
                                <div className="col-sm-8">
                                    <input id="aircraftRegistration" value={this.state.aircraftRegistration || ''} type="text" className="form-control" placeholder="Aircraft registration" required onChange={this.handleChange}></input>
                                </div>
                            </div>
                        </form>
                    </Modal.Body>
                    <Modal.Footer style={{ backgroundColor: '#2c2c2c' }}>
                        <Button variant="secondary" onClick={() => this.props.hide()}>
                            Close
                        </Button>
                        <Button variant="success" type="submit" form="newEntryForm" >
                            Save
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

//map the props of this class to the root redux state
const mapStateToProps = (state: any) => {
    return {
        userId: state.userId,
        aircraftModelList: state.lookupList[MyLookup.AircraftList],
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        saveUserAircraft: (token: any, userAircraft: UserAircraft) => dispatch<any>(actionCreator.default.userAircraft.saveUserAircraft(token, userAircraft)),
        updateUserAircraft: (token: any, userAircraft: UserAircraft) => dispatch<any>(actionCreator.default.userAircraft.updateUserAircraft(token, userAircraft)),
        getLookupList: () => dispatch<any>(actionCreator.default.lookup.getLookupList()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserAircraftPopup);