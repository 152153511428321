import axios from 'axios';
import { Weather } from '../class/Weather';
import appsettings from '../appsettings';
const apiUrl = appsettings.serverBaseUrl + '/api/Weather/';  

export const getMetar = (airfield : any) =>{
 return async (dispatch  :any) =>{
   try{
    const res = await axios.get<any>(apiUrl + 'GetMetar/' + airfield);
     return dispatch(getMetarSuccess(res.data));
   }
   catch (error) {
     throw (error)
   }
 }
}

export const getMetarSuccess = (data :any) => {
 return {
   type: "WEATHER_GET_METAR",
   payload: data
 }
}

export const getTaf = (airfield : any) =>{
  return async (dispatch  :any) =>{
    try{
     const res = await axios.get<any>(apiUrl + 'GetTaf/' +  airfield);
      return dispatch(getTafSuccess(res.data));
    }
    catch (error) {
      throw (error)
    }
  }
 }
 
 export const getTafSuccess = (data :any) => {
  return {
    type: "WEATHER_GET_TAF",
    payload: data
  }
 }

 export const getChatGptReview = (weather : Weather) =>{
  return async (dispatch  :any) =>{
    try{
     const res = await axios.post<any>(apiUrl + 'GetChatGptAdvice', weather);
      return dispatch(getChatGptReviewSuccess(res.data));
    }
    catch (error) {
      throw (error)
    }
  }
 }
 
 export const getChatGptReviewSuccess = (data :any) => {
  return {
    type: "WEATHER_CHATGPT_REVIEW",
    payload: data
  }
 }