import * as React from 'react';
import './css/Footer.css';

class Footer extends React.Component {

    render() {
        return (
            <footer className="page-footer footer">
            <div className="footer-content">
                <div className="float-left brand"><h6>MyUnivers 1.0</h6></div>
                <div className="float-right copyright">© Copyright 2024 MyUnivers. All Rights Reserved</div>
            </div>
            <div></div>
        </footer>
        
        )
    }
}

export default Footer