import * as React from 'react';
import { Modal, Button } from "react-bootstrap";
import { connect } from 'react-redux';
import * as actionCreator from '../../actions/actions';
import { Dispatch } from 'redux';
import { Log } from "../../class/Log";
import Select from 'react-select';
import { MyLookup } from '../../class/Enums';
import { UserAirport } from '../../class/UserAirport';

interface State {
    userAirportId: any;
    userAirportCode:any;
    AirportModel: any;
    AirportRegistration: any;
}

interface Props {
    popupTitle: string;
    show: boolean;
    token: any;
    userAirport: UserAirport;
    AirportModelList: any;
    userAirportList: Array<UserAirport>;
    hide(): void;
    saveUserAirport(token: any, p: UserAirport): void;
    updateUserAirport(token: any, p: UserAirport): void;
    getLookupList(): any;
}

class UserAirportPopup extends React.Component<Props, State>{
    private socket: any;

    constructor(props: any) {
        super(props)
        this.state = {
            userAirportId: 0,
            userAirportCode:"",
            AirportModel: "",
            AirportRegistration: "",
        };
    }

    componentDidMount() {
       
        this.props.getLookupList();
    }

    componentDidUpdate(nextProps: any) {
        let AirportLabel = this.props.userAirport != undefined ? this.props.userAirport.airport?.airportName : null;
        let AirportValue = this.props.userAirport != undefined ? this.props.userAirport.airport?.airportId : null;

        //Edit selected logbook
        if (this.props !== nextProps) {
            this.setState({
                userAirportId: this.props.userAirport.userAirportId,
                userAirportCode: this.props.userAirport.airport?.airportCode,
                AirportModel: { label: AirportLabel, value: AirportValue },
            })
        }
    }

    handleChange = (e: any) => {
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        this.setState({
            [e.target.id]: value
        } as any);
    }

    handleAirportChange = (AirportModel: any) => {
        this.setState({ AirportModel: AirportModel});
    };


    handleSaveLog = (e: any) => {
        e.preventDefault();
        var myAirport: UserAirport = ({
            userAirportId: this.state.userAirportId,
            airportId: this.state.AirportModel.value,
            isAirportFavorit: false
        });

        if (myAirport.userAirportId != undefined) {
            this.props.updateUserAirport(this.props.token, myAirport);
        }
        else {
            this.props.saveUserAirport(this.props.token, myAirport);
        }
        this.props.hide();
    }



    render() {
        // Extract the IDs of Airport in the user's list
        // const userAirportIds = this.props.userAirportList.map(item => item.AirportModel?.AirportModelId);
        // const filteredAirportList = this.props.AirportModelList?.filter(
        //     (model: { value: number | undefined; }) => !userAirportIds.includes(model.value)
        // );

        return (
            <div>
                <Modal className='modal-sm' show={this.props.show} onHide={() => this.props.hide()}>
                    <Modal.Header style={{ backgroundColor: '#2c2c2c' }} closeButton>
                        <Modal.Title>{this.props.popupTitle}
                        </Modal.Title>
                    </Modal.Header >
                    <Modal.Body style={{ backgroundColor: '#2c2c2c' }} >
                        <form id="newEntryForm" className="form-signin" onSubmit={this.handleSaveLog}>
                            <div className="mb-2 row">
                                <label className="col-sm-4 col-form-label">Airport</label>
                                <div className="col-sm-8">
                                    <Select id="AirportModel" value={this.state.AirportModel} onChange={this.handleAirportChange} options={this.props.AirportModelList} placeholder="Airport model" 
                                        styles={{
                                            control: (baseStyles) => ({
                                                ...baseStyles,
                                                padding: '0px',
                                                minHeight: '32px', // Ensure minimum height to avoid clipping content
                                            }),
                                            option: (provided) => ({
                                                ...provided,
                                                color: 'black', // or any other color that provides good contrast
                                            }),
                                            // Optionally, you can add more styling for other components like the menu, option, etc.
                                        }}
                                    />
                                </div>
                            </div>
                        </form>
                    </Modal.Body>
                    <Modal.Footer style={{ backgroundColor: '#2c2c2c' }}>
                        <Button variant="secondary" onClick={() => this.props.hide()}>
                            Close
                        </Button>
                        <Button variant="success" type="submit" form="newEntryForm" >
                            Save
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

//map the props of this class to the root redux state
const mapStateToProps = (state: any) => {
    return {
        userId: state.userId,
        AirportModelList: state.lookupList[MyLookup.AirportList],
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        saveUserAirport: (token: any, userAirport: UserAirport) => dispatch<any>(actionCreator.default.userAirport.saveUserAirport(token, userAirport)),
        updateUserAirport: (token: any, userAirport: UserAirport) => dispatch<any>(actionCreator.default.userAirport.updateUserAirport(token, userAirport)),
        getLookupList: () => dispatch<any>(actionCreator.default.lookup.getLookupList()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserAirportPopup);