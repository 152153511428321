
import * as React from 'react';
import { UserAircraft } from '../../class/UserAircraft';
import { UserAirport } from '../../class/UserAirport';

//Return lookup list of user aircraft from list of UserAircraft object
export function getUserAircraftLookupList(userAircraftList: UserAircraft[]): { value: number, label: string }[] {
    return userAircraftList.map(aircraft => ({
        value: aircraft.userAircraftId || 0,
        label: `${aircraft.aircraftModel?.aircraftModelName || ''} | ${aircraft.aircraftRegistration || ''}`
    }));
}

//Return lookup list of user Airport from list of UserAirport object
export function getUserAirportLookupList(userAirportList: UserAirport[]): { value: number, label: string }[] {
    return userAirportList.map(airport => ({
        value: airport.airportId || 0,
        label: `${airport.airport?.airportName || ''} | ${airport.airport?.airportCode || ''}`
    }));
}

//Return lookupItem of the user favorite aircraft
export function getUserFavoritAircraftLookup(userAircraftList: UserAircraft[]): { value: number, label: string } {
    const myFavorit = userAircraftList.find(aircraft => aircraft.isAircraftFavorit);
    return {
        value: myFavorit?.userAircraftId || 0,
        label: `${myFavorit?.aircraftModel?.aircraftModelName || ''} | ${myFavorit?.aircraftRegistration || ''}`
    };
}

//Return lookupItem of the user favorite aircraft
export function getUserFavoritAirportLookup(userAirportList: UserAirport[]): { value: number, label: string } {
    const myFavorit = userAirportList.find(aircraft => aircraft.isAirportFavorit);
    return {
        value: myFavorit?.airportId || 0,
        label: `${myFavorit?.airport?.airportName || ''} | ${myFavorit?.airport?.airportCode || ''}`
    };
}

