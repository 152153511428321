import axios from 'axios'; 
import appsettings from '../appsettings';
const apiUrl = appsettings.serverBaseUrl + '/api/UserAirport/';  


// -------------Get userAirport list ----------------
export const getUserAirportList = (accessToken: any) =>{
  return async (dispatch  :any) =>{
    try{
      //We are logged in the API so we don't need to pass again the userId
      const res = await axios.get<any>(apiUrl + "GetUserAirportList/", {headers: {Authorization: 'Bearer ' + accessToken}});
      return dispatch(getUserAirportListSuccess(res.data));
    }
    catch (error) {
      throw (error)
    }
  }
 }
 
 export const getUserAirportListSuccess = (data :any) => {
  return {
    type: "USER_AIRPORT_LIST",
    payload: data
  }
 }

 //-----------Update userAirport----------------------
 export const updateUserAirport = (accessToken: any, userAirport: any ) =>{
  return async (dispatch  :any) =>{
    try{
      //We are logged in the API so we don't need to pass again the userId
      const res = await axios.post<any>(apiUrl + "UpdateUserAirport/",userAirport, {headers: {Authorization: 'Bearer ' + accessToken}});
      return dispatch(updateUserAirportSuccess(res.data));
    }
    catch (error) {
      throw (error)
    }
  }
 }
 
 export const updateUserAirportSuccess = (data :any) => {
  return {
    type: "USER_AIRPORT_UPDATED",
    payload: data
  }
 }

  //-----------Save userAirport----------------------
  export const saveUserAirport = (accessToken: any, userAirport: any ) =>{
    return async (dispatch  :any) =>{
      try{
        //We are logged in the API so we don't need to pass again the userId
        const res = await axios.post<any>(apiUrl + "SaveUserAirport/",userAirport, {headers: {Authorization: 'Bearer ' + accessToken}});
        return dispatch(SaveUserAirportSuccess(res.data));
      }
      catch (error) {
        throw (error)
      }
    }
   }
   
   export const SaveUserAirportSuccess = (data :any) => {
    return {
      type: "USER_AIRPORT_SAVED",
      payload: data
    }
   }

    //-----------Delete userAirport----------------------
    export const deleteUserAirport = (accessToken: any, airportId? :number) =>{
      return async (dispatch  :any) =>{
        try{
          const res = await axios.get<any>(apiUrl + "DeleteUserAirport/" + airportId, {headers: {Authorization: 'Bearer ' + accessToken}});
          return dispatch(deleteUserAirportSuccess(res.data));
        }
        catch (error) {
          throw (error)
        }
      }
    }
   
   export const deleteUserAirportSuccess = (data :any) => {
    return {
      type: "USER_AIRPORT_DELETED",
      payload: data
    }
   }