import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { withOktaAuth } from '@okta/okta-react';
import './css/Tracker.css';
import { Log } from "../class/Log";
import { LookupItem } from './../class/LookupItem';
import UserAircraftPopup from "./popup/UserAircraftPopup";
import UserAirportPopup from "./popup/UserAirportPopup";
import * as actionCreator from '../actions/actions';
import { MyLookup } from '../class/Enums';
// @ts-ignore
import AircraftTypeImg from '../images/diamond.jpg'; import WorldImg from '../images/world.jpg'; import RegisterImg from '../images/registerAircraft.jpg';
import { UserAircraft } from '../class/UserAircraft';
import { UserAirport } from '../class/UserAirport';
import ConfirmPopup from './popup/ConfirmPopup';
import Badge from 'react-bootstrap/Badge';


const yearList: LookupItem[] = [];
const currentYear = new Date().getFullYear();
for (let i = currentYear; i > currentYear - 10; i--) {
    let item = { label: (i).toString(), value: i }
    yearList.push(item);
}

interface Props {
    aircraftModelList: any;
    userAircraftList: Array<UserAircraft>;
    userAirportList: Array<UserAirport>;
    oktaAuth: any;
    authState: any;
    getLookupList(): void;
    getUserAircraftList(token: any): void;
    getUserAirportList(token: any): void;
    updateUserAircraft(token: any, userAircraft: any): void;
    deleteUserAircraft(token: any, userAircraft: any): void;
    updateUserAirport(token: any, userAirport: any): void;
    deleteUserAirport(token: any, userAirport: any): void;
}

interface State {
    showAircraftPopup: boolean;
    showAirportPopup: boolean;
    showConfirmPopup: boolean;
    popupTitle: string;
    token: any;
    selectedUserAircraft: UserAircraft;
    selectedUserAirport: UserAirport;
}

//map the props of this class to the root redux state
const mapStateToProps = (state: any) => {
    return {
        aircraftModelList: state.lookupList[MyLookup.AircraftList],
        airportList: state.lookupList[MyLookup.AirportList],
        userAircraftList: state.userAircraftList,
        userAirportList: state.userAirportList,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        getLookupList: () => dispatch<any>(actionCreator.default.lookup.getLookupList()),
        getUserAircraftList: (token: any) => dispatch<any>(actionCreator.default.userAircraft.getUserAircraftList(token)),
        getUserAirportList: (token: any) => dispatch<any>(actionCreator.default.userAirport.getUserAirportList(token)),
        updateUserAircraft: (token: any, userAircraft: any) => dispatch<any>(actionCreator.default.userAircraft.updateUserAircraft(token, userAircraft)),
        deleteUserAircraft: (token: any, userAircraft: any) => dispatch<any>(actionCreator.default.userAircraft.deleteUserAircraft(token, userAircraft)),
        updateUserAirport: (token: any, userAirport: any) => dispatch<any>(actionCreator.default.userAirport.updateUserAirport(token, userAirport)),
        deleteUserAirport: (token: any, userAirport: any) => dispatch<any>(actionCreator.default.userAirport.deleteUserAirport(token, userAirport)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withOktaAuth(class LogBookAdmin extends React.Component<Props, State>{
    static displayName = LogBookAdmin.name;

    constructor(props: any) {
        super(props);

        this.state = {
            token: null,
            popupTitle: "",
            showAircraftPopup: false,
            showAirportPopup: false,
            showConfirmPopup: false,
            selectedUserAircraft: {},
            selectedUserAirport: {},
        };
    };

    async componentDidMount() {
        while (!this.props.authState) {
            await new Promise(resolve => setTimeout(resolve, 100)); // Wait for 100 milliseconds
        }
        try {
            if (!this.props.authState?.isAuthenticated) {
                this.props.oktaAuth.signInWithRedirect({ originalUri: "/" });
            }
            else {
                this.props.getLookupList();
                this.props.getUserAircraftList(this.props.authState.accessToken.accessToken);
                this.props.getUserAirportList(this.props.authState.accessToken.accessToken);
                this.setState({
                    token: await this.props.authState.accessToken.accessToken
                })
            }
        } catch (err) {
        }
    }

    handleAddAircraft = () => {
        this.setState({
            popupTitle: "Add Aircraft",
            showAircraftPopup: true
        });
    }

    handleCloseAircraftPopup = () => {
        this.setState({
            showAircraftPopup: false,
        });
    }

    handleUserAircraftFavorit = (userAircraft: UserAircraft) => {
        this.props.updateUserAircraft(this.state.token, userAircraft);
    }

    handleUserAirportFavorit = (userAirport: UserAirport) => {
        this.props.updateUserAirport(this.state.token, userAirport);
    }

    handleEditAircraft = (userAircraft: UserAircraft) => {
        this.setState({
            popupTitle: "Edit Aircraft",
            selectedUserAircraft: userAircraft,
            showAircraftPopup: true
        });
    }

    handleDeleteUserAircraft = (userAircraft: any) => {
        this.setState({
            selectedUserAircraft: userAircraft,
            showConfirmPopup: true
        });
    }

    handleConfirmDeleteUserAircraft = (confirmDelete: boolean) => {
        if (confirmDelete) {
            this.setState({ showConfirmPopup: false });
            this.props.deleteUserAircraft(this.state.token, this.state.selectedUserAircraft.userAircraftId);
        }
        else {
            this.setState({
                showConfirmPopup: false,
            });
        }
    }

    handleAddAirport = () => {
        this.setState({
            popupTitle: "Add Airport",
            showAirportPopup: true
        });
    }

    handleCloseAirportPopup = () => {
        this.setState({
            showAirportPopup: false,
        });
    }

    handleDeleteUserAirport = (userAirport: any) => {
        this.setState({
            selectedUserAirport: userAirport,
            showConfirmPopup: true
        });
    }

    handleConfirmDeleteUserAirport = (confirmDelete: boolean) => {
        if (confirmDelete) {
            this.setState({ showConfirmPopup: false });

            this.props.deleteUserAirport(this.state.token, this.state.selectedUserAirport.userAirportId);
        }
        else {
            this.setState({
                showConfirmPopup: false,
            });
        }
    }

    render() {
        if (!this.props.authState?.isAuthenticated) return <div>Loading...</div>;

        let myAircraftList = this.props.userAircraftList.map((item, index) => (
            <tr key={index}>
                <td className="d-none d-lg-block d-xl-none-block">{item.aircraftModel?.aircraftModelName}</td>
                <td>{item.aircraftRegistration}</td>
                <td>
                    {item.nbrLogs !== undefined && item.nbrLogs == 0
                        ? <button className="btn" onClick={() => this.handleDeleteUserAircraft(item)} style={{ margin: 0, padding: 0 }}><span style={{ color: "orangered" }}><i className="fa fa-times"></i></span></button>
                        : <i></i>
                    }
                </td>
                <td>
                    <button
                        onClick={() => this.handleEditAircraft(item)}
                        style={{ float: 'right', background: 'none', border: 'none', cursor: 'pointer' }}>
                        <i className="fas fa-edit" style={{ color: 'green' }}></i>
                    </button>
                </td>
                <td>
                    <button
                        onClick={() => this.handleUserAircraftFavorit(item)}
                        style={{ float: 'right', background: 'none', border: 'none', cursor: 'pointer' }}>
                        {item.isAircraftFavorit
                            ? <i className="fa-solid fa-heart" style={{ color: 'red' }}></i>
                            : <i className="fa-regular fa-heart" style={{ color: 'white' }}></i>
                        }
                    </button>
                </td>
            </tr>)
        )

        let myAirporttList = this.props.userAirportList.map((item, index) => (
            <tr key={index}>
                <td>{item.airport?.airportName} <Badge bg="primary">{item.nbrLogs}</Badge></td>
                <td>{item.airport?.airportCode}</td>
                <td>
                    <button className="btn" onClick={() => this.handleDeleteUserAirport(item)} style={{ margin: 0, padding: 0 }}><span style={{ color: "orangered" }}><i className="fa fa-times"></i></span></button>
                </td>
                <td>
                    <button
                        onClick={() => this.handleUserAirportFavorit(item)}
                        style={{ float: 'right', background: 'none', border: 'none', cursor: 'pointer' }}>
                        {item.isAirportFavorit
                            ? <i className="fa-solid fa-heart" style={{ color: 'red' }}></i>
                            : <i className="fa-regular fa-heart" style={{ color: 'white' }}></i>
                        }
                    </button>
                </td>
            </tr>)
        )

        return (
            <div className='row'>
                <div className='col-6'>
                    <div className="card text-bg-dark">
                        <div className="card-body">
                            <h5 className="card-title" style={{ float: "left", marginRight: "3px" }}>My Aircrafts</h5>
                            <div style={{ float: "left" }}>
                                <button onClick={this.handleAddAircraft} style={{ background: 'none', border: 'none', cursor: 'pointer' }}><span><i className="fa fa-plus-square fa-lg" style={{ color: 'green' }}></i></span></button>
                            </div>
                            <table className="table table-sm table-dark">
                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col" className='d-none d-lg-block d-xl-none-block'>Type</th>
                                        <th scope="col">Registration</th>
                                        <th scope="col">Del.</th>
                                        <th scope="col">Edit</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {myAircraftList}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className='col-6'>
                    <div className="card text-bg-dark">
                        <div className="card-body">
                            <h5 className="card-title" style={{ float: "left", marginRight: "3px" }}>My Airfields</h5>
                            <div style={{ float: "left" }}>
                                <button onClick={this.handleAddAirport} style={{ background: 'none', border: 'none', cursor: 'pointer' }}><span><i className="fa fa-plus-square fa-lg" style={{ color: 'green' }}></i></span></button>
                            </div>
                            <table className="table table-sm table-dark">
                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col">Name</th>
                                        <th scope="col">EASA Code</th>
                                        <th scope="col">Del.</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {myAirporttList}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                {/* <LogBookAdminPopup show={this.state.showAircraftPopup} popupTitle={this.state.popupTitle} log={this.state.selectedAircraftList} hide={this.handleCloseLogPopup} token={this.state.token} /> */}
                <UserAircraftPopup show={this.state.showAircraftPopup} popupTitle={this.state.popupTitle} token={this.state.token} hide={this.handleCloseAircraftPopup} userAircraftList={this.props.userAircraftList} userAircraft={this.state.selectedUserAircraft} />
                <UserAirportPopup show={this.state.showAirportPopup} popupTitle={this.state.popupTitle} token={this.state.token} hide={this.handleCloseAirportPopup} userAirportList={this.props.userAirportList} userAirport={this.state.selectedUserAirport} />
                <ConfirmPopup show={this.state.showConfirmPopup} hide={this.handleConfirmDeleteUserAircraft} title="Delete User Aircraft" content="Do you really want to delete this aircraft ?" />
                <ConfirmPopup show={this.state.showConfirmPopup} hide={this.handleConfirmDeleteUserAirport} title="Remove User Aircraft" content="Do you really want to remove this Airport ?" />
            </div>)
    }
}))


{/* <Select id="logBookAircraftModel" onChange={this.handleChangeYear} options={yearList} value={this.state.selectedYear}
styles={{
    control: (baseStyles) => ({
        ...baseStyles,
        padding: '0px',
        minHeight: '32px', // Ensure minimum height to avoid clipping content
        backgroundColor: '#ccd2d8',
    }),
    option: (provided) => ({
        ...provided,
        color: 'black', // or any other color that provides good contrast
    }),
    // Optionally, you can add more styling for other components like the menu, option, etc.
}}
theme={(theme) => ({
    ...theme,
    borderRadius: 5,
    colors: {
        ...theme.colors,
        primary75: 'danger'
    },
})}
/> */}