import  Home  from "./components/Home";
import  LogBookAircraft  from "./components/LogBookAircraft";
import  LogBookAdmin  from "./components/LogBookAdmin";
import { LoginCallback } from '@okta/okta-react';

const AppRoutes = [
  {
    index: true,
    path: '/',
    element: <Home />
  },
  {
    path: '/home',
    element: <Home />
  },
  {
    path: '/LogBookAircraft',
    element: <LogBookAircraft />
  },
  {
    path: '/LogBookAdmin',
    element: <LogBookAdmin />
  },
  {
    path: '/login/callback',
    element: < LoginCallback />
  }
];

export default AppRoutes;
