export enum Page {
    Home,
    Login,
    Logout,
    Tracker,
    About,
    Contact,
}

export enum MyLookup {
    AircraftList = 0,
    AirportList = 1,
    CurrencyList = 2,
    SymbolList = 3,
}