export class Weather {
    metar: string;
    taf: string;
    chatGtpReview: string;

    constructor(metar: string, taf: string, chatGtpReview: string) {
        this.metar = metar;
        this.taf = taf;
        this.chatGtpReview = chatGtpReview;
    }
}
