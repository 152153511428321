import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Link } from 'react-router-dom';
import * as actionCreator from '../actions/logBookActions';
import { withOktaAuth } from '@okta/okta-react';
import * as moment from 'moment';  //Format date
import './css/Tracker.css';
import { Log } from "../class/Log";
import LogBookPopup from "./popup/LogBookPopup";
import MyPopover from "./element/MyPopover";
import ConfirmPopup from './popup/ConfirmPopup';
import { LookupItem } from './../class/LookupItem';
import { FlyTime } from '../class/flyTime';
import Select from 'react-select';

const yearList: LookupItem[] = [];
const currentYear = new Date().getFullYear();
for (let i = currentYear; i > currentYear - 10; i--) {
    let item = { label: (i).toString(), value: i }
    yearList.push(item);
}

interface Props {
    navigation: any;

    logBookList: Array<Log>;
    flyTime: FlyTime;
    isLogSaved: boolean;
    isLogDeleted: boolean;
    oktaAuth: any;
    authState: any;
    getLogBookList(token: any, year: number): void;
    getTotalFlyTime(token: any): void;
    deleteLog(token: any, logId: any): void;
}

interface State {
    selectedLog: Log,
    showPopupLog: boolean,
    token: any;
    popupTitle: string,
    isLogSaved: boolean,
    showConfirmPopup: boolean,
    selectedYear: any,
}

//map the props of this class to the root redux state
const mapStateToProps = (state: any) => {
    return {
        logBookList: state.logBookList,
        flyTime: state.flyTime,
        isLogSaved: state.isLogSaved,
        isLogDeleted: state.isLogDeleted,
    }
}



const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        getLogBookList: (token: any, year: number) => dispatch<any>(actionCreator.getLogList(token, year)),
        deleteLog: (token: any, logId: any) => dispatch<any>(actionCreator.deleteLog(token, logId)),
        getTotalFlyTime: (token: any) => dispatch<any>(actionCreator.getTotalFlyTime(token)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withOktaAuth(class LogBookAircraft extends React.Component<Props, State>{
    static displayName = LogBookAircraft.name;


    constructor(props: any) {
        super(props);

        this.state = {
            token: null,
            popupTitle: "",
            selectedLog: {},
            showPopupLog: false,
            isLogSaved: false,
            showConfirmPopup: false,
            selectedYear: { label: new Date().getFullYear().toString(), value: new Date().getFullYear() },
        };
    };

    async componentDidMount() {
        while (!this.props.authState) {
            await new Promise(resolve => setTimeout(resolve, 100)); // Wait for 100 milliseconds
        }

        try {
            if (!this.props.authState?.isAuthenticated) {
                this.props.oktaAuth.signInWithRedirect({ originalUri: "/" });
            }
            else {
                this.props.getLogBookList(this.props.authState.accessToken.accessToken, this.state.selectedYear.value);
                this.props.getTotalFlyTime(this.props.authState.accessToken.accessToken);
                this.setState({
                    token: await this.props.authState.accessToken.accessToken
                })
            }
        } catch (err) {
            // handle error as needed
        }
    }

    handleAddLog = (log: any) => {
        this.setState({
            popupTitle: "New Log",
            selectedLog: {},
            showPopupLog: true
        });
    }

    handleEditLog = (log: any) => {
        this.setState({
            popupTitle: "Edit Log",
            selectedLog: log,
            showPopupLog: true
        });
    }

    handleDeleteLog = (log: any) => {
        this.setState({
            selectedLog: log,
            showConfirmPopup: true
        });
    }

    handleConfirmDelete = (confirmDelete: boolean) => {
        if (confirmDelete) {
            this.setState({ showConfirmPopup: false });
            this.props.deleteLog(this.state.token, this.state.selectedLog.logBookId);
        }
        else {
            this.setState({
                showConfirmPopup: false,
            });
        }
    }

    handleCloseLogPopup = () => {
        this.setState({
            showPopupLog: false,
        });
    }

    handleChangeYear = (year: any) => {
        this.setState({
            selectedYear: year,
        })
        this.props.getLogBookList(this.state.token, year.value);
    }

    //navigate = useNavigate();
    navigateAdminPage = () => {
        // navigation('/home');
    };

    render() {
        const { navigation } = this.props;

        if (!this.props.authState?.isAuthenticated) return <div>Loading...</div>;

        let flightTime = 0;
        let soloTime = 0;
        let landing = 0;
        let totalFlightTime = 0;
        let totalSoloTime = 0;
        let totalLanding = 0;

        this.props.logBookList.forEach(item => {
            landing = landing + (item.logBookLanding !== undefined ? item.logBookLanding : 0);
            flightTime = flightTime + (item.logBookTotalFlightTime !== undefined ? item.logBookTotalFlightTime : 0);

            if (!item.logBookDual) {
                soloTime = soloTime + (item.logBookTotalFlightTime !== undefined ? item.logBookTotalFlightTime : 0)
            }
        })
        soloTime = Math.round(soloTime * 100) / 100;
        flightTime = Math.round(flightTime * 100) / 100;

        let displayList = this.props.logBookList.map((item, index) => (
            <tr key={index}>
                {item.logBookDescription !== undefined
                    ?
                    <td>
                        <MyPopover content={item.logBookDescription}></MyPopover> 
                        {moment.parseZone(item.logBookDate).format('DD/MM/YYYY')}
                    </td>
                    :
                    <td> {moment.parseZone(item.logBookDate).format('DD/MM/YYYY')}</td>
                }
                <td className="d-none d-lg-table-cell">{item.userAircraft === undefined ? "" : item.userAircraft.aircraftModel?.aircraftModelName}</td>
                <td>{item.userAircraft === undefined ? "" : item.userAircraft.aircraftRegistration}</td>
                <td>{item.airportDeparture === undefined ? "" : item.airportDeparture.airportCode}</td>
                <td className="d-none d-lg-table-cell">{item.logBookDepartureTime}</td>
                <td>{item.airportArrival === undefined ? "" : item.airportArrival.airportCode}</td>
                <td className="d-none d-lg-table-cell">{item.logBookArrivalTime}</td>
                <td>{item.logBookTotalFlightTime === undefined ? "" : Math.round(item.logBookTotalFlightTime * 100) / 100}</td>
                <td className="d-none d-lg-table-cell">
                    {item.logBookIFR === true ? <i className="fas fa-check"></i> : <i></i>}
                </td>
                <td className="d-none d-lg-table-cell">
                    {item.logBookNight === true ? <i className="fas fa-check"></i> : <i></i>}
                </td>
                <td className="d-none d-lg-table-cell">
                    {item.logBookPIC === true ? <i className="fas fa-check"></i> : <i></i>}
                </td>
                <td className="d-none d-lg-table-cell">
                    {item.logBookCoPilot === true ? <i className="fas fa-check"></i> : <i></i>}
                </td>
                <td className="d-none d-lg-table-cell">
                    {item.logBookDual === true ? <i className="fas fa-check"></i> : <i></i>}
                </td>
                <td>{item.logBookLanding}</td>
                <td><button className="btn" onClick={() => this.handleDeleteLog(item)} style={{ margin: 0, padding: 0 }}><span style={{ color: "orangered" }}><i className="fa fa-times"></i></span></button></td>
                <td><button className="btn" onClick={() => this.handleEditLog(item)} style={{ margin: 0, padding: 0 }}><span style={{ color: "green" }}><i className="fas fa-edit"></i></span></button></td>
            </tr>
        ));

        return (
            <div>
                <div className='row'>
                    <div className='col-2'>
                        <div style={{ float: 'left' }}>
                            <Select id="logBookAircraftModel" onChange={this.handleChangeYear} options={yearList} value={this.state.selectedYear}
                                styles={{
                                    control: (baseStyles) => ({
                                        ...baseStyles,
                                        padding: '0px',
                                        minHeight: '32px', // Ensure minimum height to avoid clipping content
                                        backgroundColor: '#ccd2d8',
                                    }),
                                    option: (provided) => ({
                                        ...provided,
                                        color: 'black', // or any other color that provides good contrast
                                    }),
                                }}
                            />
                        </div>
                    </div>
                    <div className='col-3'>
                        {this.props.isLogSaved && <div style={{ float: "right", height: "32px", padding: "3px" }} className="alert alert-success mt-2 mr-2" role="alert"> New log added!</div>}
                        {this.props.isLogDeleted && <div style={{ float: "right", height: "32px", padding: "3px" }} className="alert alert-success mt-2 mr-2" role="alert"> Deleted!</div>}

                    </div>
                    <div className='col-7'>
                        <div className="card text-bg-dark p-0 m-0" style={{ float: 'right' }} >
                            <div className="card-header">
                                Flight time <b className="card-text mr-3">{flightTime} / <span style={{ color: 'orange' }}>{this.props.flyTime.totalFlyTime}</span></b>
                                &nbsp; &nbsp;&nbsp;Solo <b className="card-text mr-3">{soloTime} / <span style={{ color: 'orange' }}>{this.props.flyTime.totalSolo}</span></b>
                                &nbsp; &nbsp;&nbsp;Landing <b className="card-text mr-3">{landing} / <span style={{ color: 'orange' }}>{this.props.flyTime.totalLanding}</span></b>

                            </div>
                        </div>
                    </div>
                </div>

                <div className='row mt-2'>
                    <div className='col-12'>
                        <div className="card text-bg-dark">
                            <div className="card-body">
                                <h5 className="card-title" style={{ float: "left" }}>My Logs</h5>
                                <div style={{ float: "left" }}>
                                    <button onClick={this.handleAddLog} style={{ background: 'none', border: 'none', cursor: 'pointer' }}><span><i className="fa fa-plus-square fa-lg" style={{ color: 'green' }}></i></span></button>
                                   
                                </div>
                                <div style={{ float: "right" }}>
                                   <button onClick={this.handleAddLog} style={{ background: 'none', border: 'none', cursor: 'pointer' }}><span><i className="fa fa-file-excel-o fa-lg" style={{ color: 'green' }}></i></span></button>
                                   <Link to="/LogBookAdmin" onClick={this.handleAddLog} style={{ background: 'none', border: 'none', cursor: 'pointer', marginLeft: '3px' }}><span><i className="fa fa-wrench fa-lg" style={{ color: 'green' }}></i></span></Link>
                                </div>
                                <table className="table table-sm table-dark">
                                    <thead className="thead-light">
                                        <tr>
                                            <th scope="col">Date</th>
                                            <th scope="col" className="d-none d-lg-table-cell">Model</th>
                                            <th scope="col">Reg.</th>
                                            <th scope="col">Dept.</th>
                                            <th scope="col" className="d-none d-lg-table-cell">Time</th>
                                            <th scope="col">Arri.</th>
                                            <th scope="col" className="d-none d-lg-table-cell">Time</th>
                                            <th scope="col">Total</th>
                                            <th className="d-none d-lg-table-cell">IFR</th>
                                            <th className="d-none d-lg-table-cell">Night</th>
                                            <th className="d-none d-lg-table-cell">PIC</th>
                                            <th className="d-none d-lg-table-cell">CoPilot</th>
                                            <th className="d-none d-lg-table-cell">Dual</th>
                              
                                            <th >Landing</th>
                                            <th ></th>
                                            <th ></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {displayList}
                                    </tbody>
                                </table>
                            </div> </div> </div></div>
                <LogBookPopup show={this.state.showPopupLog} popupTitle={this.state.popupTitle} log={this.state.selectedLog} hide={this.handleCloseLogPopup} token={this.state.token} />
                <ConfirmPopup show={this.state.showConfirmPopup} hide={this.handleConfirmDelete} title="Delete Log" content="Do you really want to delete this log from your logbook ?" />
            </div>)
    }
}))
