const appsettings = {
  //Settings for DEV
    // loraMessageEndpoint: "http://localhost:4001",
    // oktaClientId: '0oa14m1i7a6och8DH357',
    // signalRServer: "https://localhost:5222/signalRHub",
    // serverBaseUrl: "http://localhost:5000"

  //Settings for Staging
    // loraMessageEndpoint: "http://localhost:4001",
    // oktaClientId: '0oa11ruor278W7ok7358',
    // signalRServer: "https://localhost:5222/signalRHub",
    // serverBaseUrl: "http://localhost:5000"

  //Settings for PROD
  loraMessageEndpoint: "http://dspx.eu:1884",
  oktaClientId: '0oa11toy62t5L4mSk358',
  signalRServer: "http://dspx.eu/signalRHub",
  serverBaseUrl: "https://dspx.eu"
};

export default appsettings;